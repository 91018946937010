const appURLtype =
    process.env.NODE_ENV === "production"
        ? "https://st.job-costing.mybe.software"
        : "http://localhost:8080";

module.exports = {
    // ports
    serverPort: 8080,
    //This app url
    appURL: appURLtype,
};
